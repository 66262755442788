import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { IframeService } from '../iframe.service';
import { LoginService } from 'src/app/services/login.service';
import { Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { EExchangeMessages, IPostMsgCheckOrigin } from 'src/app/models/ExchangeMessages';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit {
  @ViewChild('frame') frame: ElementRef;
  exchangeRouteChange = new Subject<string>();
  constructor(
    public iframeService: IframeService,
    private loginService: LoginService,
    private location: Location
  ) {
    super();
  }

  ngOnInit(): void {

    this.loginService.isLogIn()
      .pipe(takeUntil(this.destroy$)).subscribe((value) => {
        if (value) {
          this.rescheduleRefreshToken();
        }
      });

    this.loginService.loggedUser$.pipe(
      takeUntil(this.destroy$),
    ).subscribe((user) => {
      if (user && !user?.iframeToken) {
        this.loginService.loginV2().subscribe({
          next: (value) => {
            let user = this.loginService.getUserFromLocalStorage();
            user.iframeToken = value.token;
            this.iframeService.frameBase.searchParams.append('token', value.token);
            // console.log('chin iframe', this.iframeService.frameBase);
            this.loginService.updateUser(user);
          },
          error: (e: HttpErrorResponse) => {

          }
        });
      }
    });

    this.exchangeRouteChange.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged()
    ).subscribe((href) => {
      // console.log('Sample - location', this.location.path());
      // console.log('Sample - pushstate', href);
      if (href == '/') {
        this.location.replaceState(this.iframeService.frameBase.pathname);
      } else {
        this.location.replaceState(this.iframeService.frameBase.pathname + href);
      }
      // history.pushState(null, '', href);

    });
    this.appendHrefParam();

  }

  @HostListener('window:message', ['$event'])
  getKexhMessages(event: MessageEvent) {
    if (event.data) {
      // console.log('chin event', event);
      switch (event.data.type) {
        case EExchangeMessages.APPREADY:
          this.sendPostMessage();
          break;
        case EExchangeMessages.HEIGHTCHANGE:

          break;
        case EExchangeMessages.ROUTECHANGE:
          this.exchangeRouteChange.next(event.data.href);
          // if (event.data.title) {
          // }
          break;
        default:
          break;
      }
    }
  }

  private sendPostMessage() {
    // console.log('chin');
    const msg: IPostMsgCheckOrigin = {
      type: 'checkOrigin'
    }
    this.frame.nativeElement.contentWindow.postMessage(msg, this.iframeService.frameBase.origin);
  }

  private appendHrefParam() {
    // console.log('this.location', this.location.path());
    // console.log('this.iframeService.frameBase.pathname', this.iframeService.frameBase.pathname);
    const href = this.location.path().replace(/\//, '');
    if (href) {
      if (this.iframeService.frameBase.searchParams.has(href)) {
        this.iframeService.frameBase.searchParams.delete('href');
        this.iframeService.frameBase.searchParams.set('href', href);
      } else {
        this.iframeService.frameBase.searchParams.append('href', href);
      }
    }
  }

  private rescheduleRefreshToken() {
    const lastTimeCalled = this.loginService.getAuthTimestamp();
    if (lastTimeCalled == null || lastTimeCalled < (Date.now() - this.loginService.getRescheduleTimeToCallRefreshToken())) {
      this.loginService.refreshToken().subscribe({
        next: (userResponse) => {
          let user = this.loginService.getUserFromLocalStorage();
          user.token = userResponse.token;
          user.authTimestamp = Date.now();
          this.loginService.updateUser(user);
        },
        error: () => {
          this.loginService.doLogoutUser();
          this.iframeService.frameBase.searchParams.delete('token');
        }
      })
    }
    this.loginService.callRefreshToken().pipe(
      takeUntil(this.destroy$))
      .subscribe({
        next: (userResponse) => {
          let user = this.loginService.getUserFromLocalStorage();
          user.token = userResponse.token;
          user.authTimestamp = Date.now();
          this.loginService.updateUser(user);
        },
        error: () => {
          this.loginService.doLogoutUser();
          this.iframeService.frameBase.searchParams.delete('token');
        }
      });
  }


}
