import { Injectable } from '@angular/core';
import { ELocalStorageItems } from '../models/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  readFromStorage(key: ELocalStorageItems): any {
    let savedItem = "";
    try {
      //@ts-ignore
      savedItem = JSON.parse(localStorage.getItem(key));
    } catch (error) {
      // ignore read errors
      return null;
    }

    return savedItem;
  }
  saveInStorage(key: ELocalStorageItems, value: any) {
    try {
      if (typeof value != "boolean" && !value) value = "";
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      // ignore write errors
    }

  }

  removeFromStorage(key: ELocalStorageItems): any {
    try {
      //@ts-ignore
      JSON.parse(localStorage.removeItem(key));
    } catch (error) {
      // ignore read errors
    }

  }
}
