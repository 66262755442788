import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IBalanceRequest, IBalanceResponse, IDepositDialog } from 'src/app/models/balance';
import { LoginService } from 'src/app/services/login.service';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { HttpClient } from '@angular/common/http';
import { FormHelperService } from 'src/app/services/form-helper.service';

@Component({
  selector: 'app-deposit-withdraw-dialog',
  templateUrl: './deposit-withdraw-dialog.component.html',
  styleUrls: ['./deposit-withdraw-dialog.component.scss']
})
export class DepositWithdrawDialogComponent extends BaseComponent implements OnInit {
  transferForm: FormGroup;
  isLoggedIn: boolean;
  firstName;
  balance;
  ccy;
  parentBalance: IBalanceResponse;

    constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IDepositDialog,
    private dialogRef: MatDialogRef<DepositWithdrawDialogComponent, IBalanceRequest>,
    private loginService: LoginService,
    private http: HttpClient,
    private formHelper: FormHelperService,
  ) {
    super();
  }
  ngOnInit(): void {
    if (this.data.mod === 'Deposit') {
      this.loginService.isLogIn()
      .pipe(takeUntil(this.destroy$)).subscribe((value) => {
        this.isLoggedIn = value;
        if (value) {
          const user = this.loginService.getUserFromLocalStorage();

          if (user) {
            this.firstName = user.firstName;
            this.balance = user.availableBalance;
            this.ccy = user.currency;
          }
        }
      });
    }

    this.transferForm = this.fb.group({
      amount: ['', [Validators.required, Validators.min(0.01)]],
      type: [this.data.mod.toUpperCase(), Validators.required]
    });

    if (this.data.mod === 'Withdraw') {
      this.amount.setValidators([Validators.required, Validators.min(0.01), Validators.max(+this.loginService.getBalanceFromUser())])
    }
  }

   onSubmit() {

    if (this.transferForm.invalid) {
      this.formHelper.checkForm(this.transferForm);
      return;
    }
    this.dialogRef.close(this.transferForm.value);
  }
  
  get amount(): AbstractControl {
    return this.transferForm.get('amount');
  }
}
