<div class="content">
    <h1 mat-dialog-title>{{(data.mod === 'Deposit' ? 'Deposit Amount' : 'Withdrawal Amount') | translate }}</h1>
    <div mat-dialog-content >
        <form [formGroup]="transferForm">
            <mat-form-field floatLabel="never" appearance="outline" >
                <input matInput formControlName="amount" type="number" class="example-right-align" placeholder="0.00">
                <span matTextPrefix>{{ccy}}</span>
                <mat-error *ngIf="amount.hasError('required')">{{'Field is required' | translate}}</mat-error>
                <mat-error *ngIf="amount.hasError('min')">{{'Amount should be positive' | translate}}</mat-error>
                <mat-error *ngIf="amount.hasError('max')">{{'Insufficient funds' | translate}}</mat-error>
                <mat-error *ngIf="amount.hasError('pattern')">{{'Invalid Amount' | translate}}</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div mat-dialog-actions class="action-box">
        <button mat-button mat-dialog-close>{{'Cancel' | translate}}</button>
        <button mat-button (click)="onSubmit()" class="ok-btn">{{(data.mod === 'Deposit' ? 'ADD DEPOSIT' : 'WITHDRAW') | translate}}</button>
    </div>
</div>

