import { TranslateService } from "@ngx-translate/core";
import { CookieOptions, CookieService } from "ngx-cookie-service";
import { langCodes } from '../components/topbar/config';
import langs from '../../translate/index';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TopbarService {

    public readonly LANG_COOKIE_NAME = "COOKIE_LANGUAGE";

    public selectedLang: string;
    public selectedMainLang: string;
    

    constructor(
        private translate: TranslateService,
        private cookieService: CookieService) 
    {

        const browserLang = this.translate.getBrowserCultureLang().toUpperCase().replace("-","_");
        this.selectedLang = (Object.keys(langCodes).indexOf(browserLang) !== -1 ? browserLang : "EN");
        if(!this.cookieService.check(this.LANG_COOKIE_NAME)){
            this.setEwlCookie(this.LANG_COOKIE_NAME, this.selectedLang, false);
        }else{
            this.selectedLang = this.cookieService.get(this.LANG_COOKIE_NAME).toUpperCase();
        }

        this.selectedMainLang = this.selectedLang.toLowerCase();
        this.translate.setTranslation(this.selectedMainLang, langs[this.selectedMainLang]);
        this.translate.use(this.selectedMainLang);

    }

    public setEwlCookie(cookieName, value, reload = true){
        this.cookieService.set(cookieName, value, this.getCookieOptions());
        if (reload){
            location.reload();
        }
    }

    private getMainHostName(){
        const hostName = window.location.hostname;
        const splitHostName = hostName.split(".");
        return "."+splitHostName[splitHostName.length-2] +"."+splitHostName[splitHostName.length-1];
    }

    private getCookieOptions( override?:CookieOptions ): CookieOptions {
        const hostName = window.location.hostname;
        const mainHostName = this.getMainHostName();

        const options: CookieOptions = {
            path: "/",
            domain: (hostName == "localhost" ? null : mainHostName),
            secure: (hostName == "localhost" ? false : true),
            sameSite: "None",
        };
        if (override){
            Object.assign(options, override);
        }
        return options;
    }
}