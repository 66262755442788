<div class="login-form">
    <form [formGroup]="loginForm" class='login-wrapper'>
        <input matInput placeholder="{{'Username' | translate}}" name="username" formControlName="username" required>
        <div class="pw-err">
            <div class="pass-input">
                <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password"
                    required>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </div>
            <mat-error class="alert" *ngIf="password.invalid || username.invalid">{{showError}}</mat-error>
        </div>
        <button type="submit" (click)="onLogin()">{{'Login' | translate}}</button>
    </form>
</div>