export const langCodes = {
  "PT_BR": {
    label: "BR",
    code: "PT_BR"
  },
  "EN" : {
    label: "EN",
    code: "EN"
  },
  "ZH_TW": { code: 'ZH_TW', label: '中國傳統的' },
  "ZH_CN" :{ code: 'ZH_CN', label: '简体中文' }
}