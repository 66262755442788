import { HttpClient } from "@angular/common/http";
import { ILoginRequest, ILoginResponse, ILoginV2Response } from "../models/login";
import { BehaviorSubject, Observable, interval, mergeMap } from "rxjs";
import { Injectable } from "@angular/core";
import { IframeService } from "../codigree/iframe.service";
import { IBalanceRequest, IBalanceResponse } from "../models/balance";
import { StorageService } from "./storage.service";
import { ELocalStorageItems } from "../models/storage";


@Injectable({
    providedIn: 'root'
})
export class LoginService {
    private RESCHEDULE_REFRESH_TOKEN_MS = 80 * 60 * 1000; //80 minutes
    public loggedUser$: BehaviorSubject<ILoginResponse> = new BehaviorSubject<ILoginResponse>(this.checkIfUserIsLogged());

    private isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.checkUserLoggedIn());

    constructor(
        private http: HttpClient,
        private iframeService: IframeService,
        private storageService: StorageService
    ) {

    }

    login(user: ILoginRequest): Observable<ILoginResponse> {

        return this.http.post<ILoginResponse>('auth/login', user);
    }

    loginV2(): Observable<ILoginV2Response> {

        return this.http.post<ILoginV2Response>('exchange/login', {});
    }

    refreshToken(): Observable<ILoginResponse> {
        return this.http.post<ILoginResponse>('auth/refresh-token', {});
    }

    updateUser(user: ILoginResponse) {
        this.loggedUser$.next(user);
        if (user) {
            this.storageService.saveInStorage(ELocalStorageItems.USER, user);
        }
    }

    callRefreshToken() {
        return interval(this.RESCHEDULE_REFRESH_TOKEN_MS).pipe(
            mergeMap(() => {
                return this.refreshToken();
            })
        )
    }


    doLoginUser(user: ILoginResponse) {
        user.authTimestamp = Date.now();
        this.storageService.saveInStorage(ELocalStorageItems.USER, user);
        this.updateUser(user);
        this.updateLoggenIn(true);
    }

    doLogoutUser() {
        this.storageService.removeFromStorage(ELocalStorageItems.USER);
        this.updateUser(null);
        this.isLoggedIn$.next(false);
        location.reload();
    }

    checkUserLoggedIn(): boolean {
        if (this.getUserFromLocalStorage() != null) {
            if (!this.iframeService.frameBase.searchParams.has('token')) {
                const token = this.getTokenFromIFrame();
                if (token != null) {
                    this.iframeService.frameBase.searchParams.append('token', token);
                }
            }
            return true;
        }
        return false;
    }

    checkIfUserIsLogged(): ILoginResponse | null {
        if (this.getUserFromLocalStorage() != null) {
            return this.getUserFromLocalStorage();
        }

        return null;
    }


    isLogIn() {
        return this.isLoggedIn$;
    }

    updateLoggenIn(isLogged: boolean) {
        this.isLoggedIn$.next(isLogged);
    }

    getUserFromLocalStorage(): ILoginResponse | null {
        return this.storageService.readFromStorage(ELocalStorageItems.USER);
    }

    getTokenFromLS() {
        const user = this.getUserFromLocalStorage();
        return user ? user.token : null;
    }

    getTokenFromIFrame() {
        const user = this.getUserFromLocalStorage();
        if (user) {
            return user.iframeToken ? user.iframeToken : null;
        }
        return null;
    }

    getAuthTimestamp() {
        const user = this.getUserFromLocalStorage();
        return user ? user.authTimestamp : null;
    }

    putCashBalance(item: IBalanceRequest): Observable<any> {
        return this.http.put<IBalanceResponse>('user/transfer', item);
    }


    getBalanceFromUser() {
        const user = this.getUserFromLocalStorage();
        return user.availableBalance ? user.availableBalance : null;
    }

    getCashBalance(): Observable<any> {
        return this.http.get<IBalanceResponse>('user/balance');
    }

    getRescheduleTimeToCallRefreshToken() {
        return this.RESCHEDULE_REFRESH_TOKEN_MS;
    }
}