import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormHelperService {

  constructor() { }

  getFormControlIndexByName(name: string, marginsMap: string[]) {
    return marginsMap.findIndex(marginName => marginName === name);
  }

  checkForm(control: FormGroup | FormArray) {
    Object.keys(control.controls).forEach(field => {
      const item = control.get(field);
      if (item instanceof FormArray || item instanceof FormGroup) {
        this.checkForm(item);
      } else {
        item.markAsTouched();
      }
    });
  }
}