import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, catchError } from "rxjs";
import { LoginService } from "./services/login.service";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  // baseUrl = "http://192.168.1.170:8083/client/api/";

  private readonly LOGIN_ENDPOINT_NAME = 'login';
  private readonly KEXH = 'kexh';
  private token;
  constructor(
    @Inject('BASE_API_URL') private baseUrl: string,
    private loginService: LoginService,
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = this.loginService.getTokenFromLS();
    const configuredRequest: HttpRequest<any> = this.getConfiguredRequest(req);
    return next.handle(configuredRequest)
    // return next.handle(configuredRequest).pipe(
    //   catchError(
    //     error => {
    //       if (error instanceof HttpErrorResponse && error.status === 401 || error.status === 403) {
    //         if (this.loginService.isLogIn()) {
    //           return this.handle401Error(configuredRequest, next);
    //         }
    //       } else {
    //         throw new error(error);
    //     }

    //     }));
  }

  private getConfiguredRequest(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      headers: this.token ? request.headers.append('Authorization', `Bearer ${this.token}`) : request.headers,
      withCredentials: true,
      url: this.baseUrl + request.url,
    });
  }

  // private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  //   return this.loginService
  //   if (!this.isRefreshing) {
  //     this.isRefreshing = true;
  //     return this.authService.refreshToken().pipe(
  //       switchMap((response: HttpResponse<any>) => {
  //         if (response.body && response.body.refreshedToken){
  //           this.authService.updateUserToken(response.body.refreshedToken);
  //         }
  //         this.isRefreshing = false;
  //         return next.handle(request);
  //       }),
  //       catchError(err => {
  //         this.isRefreshing = false;
  //         // this.notification.alert({ message: 'You are unauthorized. Please, login to perform this action' });
  //         this.authService.doLogoutUser();
  //         return throwError(err);
  //       }),
  //     );

  //   }

  //   return next.handle(request);
  // }

}