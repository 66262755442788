import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material.module';
import { TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';
import { MainComponent } from './codigree/main/main.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiInterceptor } from './http.interceptor';
import { SafePipe } from './shared/safe.pipe';
import { BaseComponent } from './shared/components/base/base.component';
import { DepositWithdrawDialogComponent } from './components/dialogs/deposit-withdraw-dialog/deposit-withdraw-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { environment } from 'src/environments/environment';

const imports = [
  BrowserModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  MaterialModule,
  TranslateModule.forChild(),
  HttpClientModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
]

@NgModule({
  declarations: [
    AppComponent,
    LoginFormComponent,
    TopbarComponent,
    MainComponent,
    SafePipe,
    BaseComponent,
    DepositWithdrawDialogComponent

  ],
  imports: imports,
  providers: [
    TranslateService,
    TranslateStore,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: "BASE_API_URL",
      useValue: environment.apiUrl
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
