import { Injectable } from "@angular/core";
import { ILoginResponse } from "../models/login";
import { TopbarService } from "../services/topbar.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class IframeService {

  user: ILoginResponse
  frameBase: URL = new URL(environment.iframeUrl);
  lang = this.topbarService.selectedLang;
  public loggedInToken = '';

  constructor(private topbarService: TopbarService) {
    this.frameBase.searchParams.append('lang', this.lang);
  }

}