import en from './en.json';
import pt_br from './pt_br.json';
import zh_tw from './zh_tw.json';
import zh_cn from './zh_cn.json';

export default {
  en:en,
  pt_br:pt_br,
  zh_tw:zh_tw,
  zh_cn:zh_cn,
};
