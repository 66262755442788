import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ILoginRequest, ILoginResponse } from 'src/app/models/login';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  showError = '';
  loginForm: FormGroup;
  public hide: boolean = true;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private loginService: LoginService,
  ) {

  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
    this.loginForm.valueChanges.subscribe(() => {
      if (this.loginForm.touched && this.loginForm.invalid) {
        this.showError = ''
        this.loginForm.reset({
          username: this.username.value,
          password: this.password.value
        })
      }
    })
  }

  onLogin() {

    this.loginService.login(this.loginForm.value).subscribe(
      {
        next: (res: ILoginResponse) => {
          res.authTimestamp = Date.now();
          this.loginService.doLoginUser(res);

        },
        error: (e: HttpErrorResponse) => {
          const error = {
            validationError: true,
          };
          this.username.setErrors(error);
          this.password.setErrors(error);
          if (e.status == 0 || e.status >= 500) {
            this.showError = e.statusText;
          } else {
            this.showError = e.error.message;
          }
        },
        complete: () => {
        }
      }
    );
  }

  public togglePasswordVisibility(): void {
    this.hide = !this.hide;
  }



  get username(): FormControl {
    return this.loginForm.get('username') as FormControl;
  }

  get password(): FormControl {
    return this.loginForm.get('password') as FormControl;
  }
}
